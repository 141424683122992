/**
 * @param date
 * @returns {number}
 */
import moment from 'moment'

export const getAge = (date, isUSA) => {
  if (!date) {
    return 0
  }

  const birthDate = moment(date, isUSA ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
  return moment().diff(birthDate, 'years')
}

/**
 * @param day {string}
 * @returns {string}
 */
export const formatDay = (day) => {
  return (day.length === 1) ? `0${day}` : day
}
