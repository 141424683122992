import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import { useLocation } from '@reach/router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { customerManager } from '@/recoil/customer'
import { marketCodeManager } from '@/recoil/marketCode'
import { countryState } from '@/recoil/ageGate'

import moment from 'moment'

import { checkFormData } from '@/utils/forms'
// import { getAge } from '@/utils/date'
import countriesData from '@/config/legal_age.json'
import { AGE_GATE } from '@/utils/gtmEvents'

import SelectField from '@/components/Forms/Inputs/Select'
import DateField from '@/components/Forms/Inputs/DateField'
import CheckboxField from '@/components/Forms/Inputs/Checkbox'
import Button from '@/components/Button'

import {
  CheckBoxWrapper,
  Container,
  ErrorLabel,
  ErrorWrapper,
  FormWrapper, InputDateWrapper,
  InputWrapper,
  Label,
  LabelWrapper,
  SubmitWrapper
} from './style'
import PropTypes from 'prop-types'

const SELECT_LAYOUT_YEAR_ONLY = [false, false, true]
const SELECT_LAYOUT_MONTH_YEAR = [false, true, true]
const SELECT_LAYOUT_DAY_MONTH_YEAR = [true, true, true]

const AgeForm = ({
  onSuccess = () => {
  }
}) => {
  const setCustomerValue = useSetRecoilState(customerManager)
  const [marketCode, setMarketCode] = useRecoilState(marketCodeManager)
  const [countryName, setCountryName] = useRecoilState(countryState)
  const [error, setError] = useState(false)
  const selectRef = useRef(null)
  const yearRef = useRef(null)
  const monthRef = useRef(null)
  const dayRef = useRef(null)
  const checkBoxRef = useRef(null)
  const [isUsa, setisUsa] = useState(false)
  const {
    pathname,
    origin
  } = useLocation()
  const [defaultCountry, setDefaultCountry] = useState(null)
  const [selectLayout, setSelectLayout] = useState(SELECT_LAYOUT_YEAR_ONLY)

  const getUserCountry = async () => {
    try {
      const response = await axios.get(origin)
      const countryCode = response.headers?.['x-aka-country']
      if (countryCode) {
        const country = countriesData.countries.find((country) => country.countryCode === countryCode)
        if (country) setDefaultCountry(country)
      } else {
        console.warn('L\'en-tête \'x-aka-country\' est introuvable ou undefined')
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du pays de l\'utilisateur :', error)
    }
  }

  const getAge = (date, isUSA, full) => {
    if (!date) {
      return 0
    }

    let birthDate
    if (isUSA) {
      birthDate = moment(date, 'MM/DD/YYYY')
    } else if (full) {
      birthDate = moment(date, 'DD-MM-YYYY')
    } else {
      birthDate = moment(date + '-01-01', 'YYYY-MM-DD')
    }

    return Math.abs(birthDate.diff(moment(), 'years'))
  }

  const validateDate = (value, country, full = false) => {
    let isValid = false
    let offset = 1
    const minAge = countriesData.countries.find((c) => c.label === country).legal_age
    const expectedLength = (isUsa || full) ? 10 : 4

    if (minAge >= 0 && value.length === expectedLength) {
      const age = getAge(value, isUsa, full)
      offset = minAge - age
      isValid = (age >= minAge)
    }

    return {
      isValid,
      offset
    }
  }

  useEffect(() => {
    getUserCountry()

    const event = {
      country: 'WW',
      page_type: 'Age Gate',
      page_top_category: 'Age Gate'
    }
    if (typeof window !== 'undefined' && window.dataLayer && (dataLayer[dataLayer.length - 1].country !== event.country || dataLayer[dataLayer.length - 1].page_type !== event.page_type)) {
      window.dataLayer.push(event)
    }
  }, [])

  const handleSubmit = () => {
    const formData = checkFormData(
      [
        selectRef,
        yearRef,
        checkBoxRef,
        ...(selectLayout !== SELECT_LAYOUT_YEAR_ONLY
          ? (selectLayout === SELECT_LAYOUT_MONTH_YEAR
              ? [monthRef]
              : [monthRef, dayRef])
          : []
        )
      ]
    )
    console.log(formData)

    if (!formData.errors.length) {
      const {
        data: {
          country,
          birth: dateValue
        }
      } = formData
      let isDateValid = false
      if (selectLayout !== SELECT_LAYOUT_YEAR_ONLY) {
        const isTotal = selectLayout === SELECT_LAYOUT_DAY_MONTH_YEAR
        const date = `${isTotal ? dayRef.current.getValue() : '01'}-${monthRef.current.getValue()}-${yearRef.current.getValue()}`
        isDateValid = validateDate(date, country, true).isValid
      } else {
        isDateValid = validateDate(dateValue, country).isValid
      }
      const countryData = countriesData.countries.find((c) => c.label === country)

      if (countryData.market === 'en-us') {
        formData.data.birth = moment(dateValue, 'MM/DD/YYYY').format('DD/MM/YYYY')
      } else {
        formData.data.birth = moment(dateValue + '-01-01', 'YYYY-MM-DD').format('DD/MM/YYYY')
      }

      if (!countryData.is_authorized || !isDateValid) {
        setTimeout(() => {
          navigate('https://www.wineinmoderation.eu/')
        }, 5000)
      }

      if (!countryData.is_authorized) {
        setError('country')
      } else {
        if (isDateValid) {
          // gtm
          setCustomerValue({
            ...formData.data,
            countryCode: countryData.countryCode
          })
          setMarketCode(countryData.market)

          window.dataLayer && window.dataLayer.push({
            event: AGE_GATE,
            request_country: countryData.countryCode,
            age_gate_type: 'Pass' // 'Show' || 'Pass'
          })

          const splittedPathname = pathname.split('/')
          const marketCodeFromPathname = splittedPathname[1]

          if (countryData.market !== marketCodeFromPathname) {
            const baseUrl = marketCodeFromPathname === '' ? window.location.origin + '/' : window.location.origin
            window.location = `${baseUrl}${window.location.pathname.replace(marketCodeFromPathname, countryData.market)}`
          } else {
            if (onSuccess) {
              onSuccess()
            }
          }
        } else {
          yearRef.current.triggerError(true)
          setError('age')
        }
      }
    }
  }

  const onCountryChange = (value) => {
    setCountryName(value)
    setError(false)
    const countryData = countriesData.countries.find((c) => c.label === value)
    window.dataLayer && window.dataLayer.push({
      event: AGE_GATE,
      request_country: countryData ? countryData.countryCode : countryName,
      age_gate_type: 'Show'
    })

    handleChange(selectLayout === SELECT_LAYOUT_YEAR_ONLY ? 'year' : 'month')
  }

  useEffect(() => {
    setisUsa(countryName === 'USA')
    if (countryName === 'USA') setSelectLayout(SELECT_LAYOUT_YEAR_ONLY)
  }, [countryName])

  const handleChange = (input) => {
    setError(false)
    if (input === 'month') {
      setSelectLayout(SELECT_LAYOUT_YEAR_ONLY)
      const currentMonth = new Date().getMonth() + 1 // Add 1 to make it 1-based
      const ageMonth = parseInt(monthRef.current.getValue())
      const needDay = ageMonth === currentMonth
      setSelectLayout(needDay ? SELECT_LAYOUT_DAY_MONTH_YEAR : SELECT_LAYOUT_MONTH_YEAR)
    } else if (input === 'year') {
      setSelectLayout(SELECT_LAYOUT_YEAR_ONLY)
      const dateValue = yearRef.current.getValue()
      const country = selectRef.current.getValue()
      if (country.length && dateValue.length) {
        const validResult = validateDate(dateValue, country)
        setSelectLayout(validResult.offset === 0 ? SELECT_LAYOUT_MONTH_YEAR : SELECT_LAYOUT_YEAR_ONLY)
      }
    }
  }

  return (
    <Container>
      <LabelWrapper>
        <Label>
          Please indicate your country/region and your date of birth
        </Label>
      </LabelWrapper>
      <FormWrapper>
        <InputWrapper>
          <SelectField
            ref={ selectRef }
            label='Select your country'
            name='country'
            nameSet='Countries'
            separation
            list={ countriesData.countries.map((country) => country.label) }
            isRequired
            onChange={ onCountryChange }
            withQuickSearch
            defaultValue={ defaultCountry?.label || '' }
          />
        </InputWrapper>
        <InputDateWrapper>
          {(selectLayout[0])
            ? <DateField
                ref={ dayRef }
                name='birth_day'
                label='JJ'
                onChange={ (e) => handleChange(e) }
                isUSA={ isUsa }
                mode='day'
                isRequired
              />
            : <></>}
          {(selectLayout[1])
            ? <DateField
                ref={ monthRef }
                name='birth_month'
                label='MM'
                onChange={ (e) => handleChange(e) }
                isUSA={ isUsa }
                mode='month'
                isRequired
              />
            : <></>}
          {(selectLayout[2])
            ? <DateField
                ref={ yearRef }
                name='birth'
                label={ isUsa ? 'MM/DD/YYYY' : 'YYYY' }
                onChange={ (e) => handleChange(e) }
                isUSA={ isUsa }
                mode='year'
                isRequired
                //defaultValue='1975'
              />
            : <></>}
        </InputDateWrapper>
        <CheckBoxWrapper>
          <CheckboxField
            ref={ checkBoxRef }
            name='remember_me'
            onChange={ () => setError(false) }
          >
            Remember me <i>(do not tick the box if your device is shared)</i>
          </CheckboxField>
        </CheckBoxWrapper>
        <SubmitWrapper>
          <Button type='green-alt' onClick={ () => handleSubmit() }>
            ENTER
          </Button>
        </SubmitWrapper>
        <ErrorWrapper active={ error }>
          {error === 'age' && (
            <ErrorLabel>
              Unfortunately you cannot enter this website as you are not of legal drinking and purchasing age.
              Would you like more information on <a href='http://www.wineinmoderation.eu'>responsible drinking</a>?
            </ErrorLabel>
          )}
          {error === 'country' && (
            <ErrorLabel>
              Due to regulations in your country, you cannot access this website.
            </ErrorLabel>
          )}
        </ErrorWrapper>
      </FormWrapper>
    </Container>
  )
}

AgeForm.propTypes = {
  onSuccess: PropTypes.func
}

export default AgeForm
