import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { mediaMax } from '@/styles/mixins'
import backgroundImage from '@/images/AgeGate/background.jpeg'

export const Container = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5rem 0;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
`
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`

export const LogoBgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  z-index: -1;
`

export const LogoWrapper = styled.h1`
  width: 20%;

  ${mediaMax.xs}{
    width: 55%;
  }
`

export const LogoTitle = styled.span`
  display: none;
`

export const Content = styled.div`
  width: max(35%, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;

  ${mediaMax.xs} {
    width: 90%;
  }
`

export const LegalsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  --legals-font-size: 1rem;

  ${mediaMax.xs} {
    width: 80%;
  }

  ${mediaMax.md} {
    @media (max-height: 700px) {
      --legals-font-size: 0.8rem;
    }
  }
`

export const Legals = styled.p`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.white};
  font-size: var(--legals-font-size);
  line-height: 1.3rem;
  text-align: center;
`

export const TextLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => `${theme.colors.white}90`};
  font-size: var(--legals-font-size);
  cursor: pointer;
  text-decoration: underline;
`
