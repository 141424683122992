import { useUpdateEffect } from '@/hook'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import PropTypes from 'prop-types'

import Cleave from 'cleave.js/react'

import { CleaveWrapper, Container, InputWrapper, Label, Wrapper } from './style'
import SelectField from '@/components/Forms/Inputs/Select'
import countriesData from '@/config/legal_age.json'

const DateField = forwardRef(({
  label = '',
  isRequired = false,
  onChange = null,
  validator = null,
  name = '',
  isUSA = false,
  mode = 'year',
  defaultValue = null
}, ref) => {
  // LOCAL VALUE
  const [value, setValue] = useState('')
  const [valueList, setValueList] = useState([])
  const [error, setError] = useState(false)
  useEffect(() => {
    let list = []
    switch (mode) {
      case 'day':
        list = dayList()
        break
      case 'month':
        list = monthList()
        break
      case 'year':
      default:
        list = birthdateList()
        break
    }
    setValueList(list)
  }, [mode])
  // ON COMPONENT UPDATE
  useUpdateEffect(() => {
    checkStatus()
    if (onChange) {
      onChange(mode)
    }
  }, [value])

  const birthdateList = () => {
    const date = new Date()
    const currentYear = date.getFullYear()
    return Array.from({ length: 100 }, (_, i) => ({
      value: String(currentYear - i),
      label: String(currentYear - i)
    }))
  }

  const dayList = () => {
    return Array.from({ length: 31 }, (_, i) => ({
      value: String(i + 1).padStart(2, '0'), // Ensures two-digit format (e.g., "01", "02")
      label: String(i + 1)
    }))
  }
  const monthList = () => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]

    return monthNames.map((name, i) => ({
      value: String(i + 1).padStart(2, '0'), // Ensures two-digit format (e.g., "01", "02")
      label: name
    }))
  }

  // PRIVATE METHODS
  const handleChange = (newVal) => {
    setValue(newVal)
  }

  const handleYearChange = (selectedYear) => {
    setValue(selectedYear)
  }

  const checkStatus = () => {
    let err = false

    if (isRequired) {
      if (!value.length) {
        err = true
      }
      if (value.length && validator) {
        err = validator(value)
      }
    }

    setError(err)
    return err
  }

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({
    isRequired: () => isRequired,
    getName: () => name,
    getValue: () => value,
    hasError: () => error,
    triggerError: (e) => setError(e),
    isValid: () => !checkStatus()
  }))

  return (
    <Container>
      <Wrapper error={ error }>
        <InputWrapper>
          {isUSA && (
            <CleaveWrapper>
              <Label htmlFor='birthdate'>Enter your date of birth</Label>
              <Cleave
                id='birthdate'
                title='birthdate'
                placeholder={ label }
                options={ {
                  date: true,
                  datePattern: ['m', 'd', 'Y'],
                  blocks: [2, 2, 4]
                } }
                onChange={ (e) => handleChange(e.target.value) }
              />
            </CleaveWrapper>
          )}
          {!isUSA && (
            <>
              <SelectField
                name='birthdate'
                nameSet={ mode[0].toUpperCase() + mode.slice(1) }
                label={ label || 'Select year' }
                list={ valueList }
                onChange={ handleYearChange }
                isRequired={ isRequired }
                defaultValue={ defaultValue || label || 'Select year' }
                withQuickSearch
              />
            </>
          )}
        </InputWrapper>
      </Wrapper>
    </Container>
  )
})

DateField.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  name: PropTypes.string,
  isUSA: PropTypes.bool
}

export default DateField
